import React from "react";
import "./RecordingIndicator.css";

export default function RecordingIndicator() {
  return (
    <div className="recording-indicator">
      <div className="circle red"></div>
      <span>Recording</span>
    </div>
  );
}